import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import "react-chat-elements/dist/main.css";
import "./chat.css";
import "bootstrap/dist/css/bootstrap.css";
import { MessageBox, ChatItem } from "react-chat-elements";
import * as DataSources from './data_sources';
import * as MathJax from "better-react-mathjax";

// Global constant for testing
const TEST = false;

const formatMessage = (text) => {
  const codeBlockRegex = /```python([\s\S]*?)```/g;
  const codeInlineRegex = /`([^`]+)`/g;
  
  const parts = [];
  let lastIndex = 0;

  text.replace(codeBlockRegex, (match, p1, offset) => {
    if (lastIndex < offset) {
      parts.push(text.substring(lastIndex, offset));
    }
    parts.push(
      <code-runner language="python3" key={offset}>
        {p1.trim()}
      </code-runner>
    );
    lastIndex = offset + match.length;
  });

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  let subParts = parts.map((part, index) => {
    if (typeof part === 'string') {
      const subParts = [];
      let lastInlineIndex = 0;

      part.replace(codeInlineRegex, (match, p1, offset) => {
        if (lastInlineIndex < offset) {
          subParts.push(part.substring(lastInlineIndex, offset));
        }
        subParts.push(<code key={`${index}-${offset}`}>{p1}</code>);
        lastInlineIndex = offset + match.length;
      });

      if (lastInlineIndex < part.length) {
        subParts.push(part.substring(lastInlineIndex));
      }

      return subParts;
    }
    return part;
  }).flat();

  const formulaRegex = /\\(\[|\()([\s\S]*?)\\(\]|\))/g;

  subParts = subParts.map((subPart, subIndex) => {
    if (typeof subPart === 'string') {
      console.log(subPart);
      // Process formulas
      const formulaProcessedParts = [];
      let lastIndex = 0;

      subPart.replace(formulaRegex, (match, p1, p2, p3, offset) => {
        if (lastIndex < offset) {
          formulaProcessedParts.push(subPart.substring(lastIndex, offset));
        }
        const formula = `\\[${p2}\\]`;
        console.log(formula);
        formulaProcessedParts.push(
          <MathJax.MathJax>{formula}</MathJax.MathJax>
        );
        lastIndex = offset + match.length;
      });

      if (lastIndex < subPart.length) {
        formulaProcessedParts.push(subPart.substring(lastIndex));
      }

      // Process newlines within the formula-processed parts
      return formulaProcessedParts.map((fp) => {
        if (typeof fp === 'string') {
          return fp.split('\n').reduce((acc, line, lineIndex) => {
            if (lineIndex > 0) acc.push(<br />);
            acc.push(line);
            return acc;
          }, []);
        }
        return fp;
      }).flat();
    }
    return subPart;
  }).flat();

  return subParts;
};

// Mocked data
const mockedChats = [
  {
    chat_id: "user1",
    title: "Chat 1",
    subtitle: "Last message from Chat 1",
    date: new Date(),
    unread: 2,
  },
  {
    chat_id: "user2",
    title: "Chat 2",
    subtitle: "Last message from Chat 2",
    date: new Date(),
    unread: 1,
  },
];

const mockedMessages = {
  user1: [
    {
      from_id: "user1",
      to_id: "currentUserId",
      text: "Hello from Chat 1 my best friend",
      create_dt: new Date().toISOString(),
    },
    {
      from_id: "currentUserId",
      to_id: "user1",
      text: "<p>Hi</p><br/>  there!",
      create_dt: new Date().toISOString(),
    },
  ],
  user2: [
    {
      from_id: "user2",
      to_id: "currentUserId",
      text: "Hello from Chat 2 my best friend\n\\[\\frac{d}{dx} x^n = nx^{n-1}\\]\n `sample`\\(\\frac{d}{dx} x^n = nx^{n-1}\\)\n```python\nprint(\"Hello from Chat 2 my best friend\")\n\nprint(\"lol\")```\n```python\nprint(\"Hello from Chat 2 my best friend\")\n```",
      create_dt: new Date().toISOString(),
    },
    {
      from_id: "currentUserId",
      to_id: "user2",
      text: "Hello!",
      create_dt: new Date().toISOString(),
    },
  ],
};

const message = (msg, to_id) => {
  return (
    <MessageBox
      position={msg.from_id === to_id ? "left" : "right"}
      type={"text"}
      text={formatMessage(msg.text)}
      date={new Date(msg.create_dt)}
      style={{ width: "100%" }}
    />
  );
};

const chatItem = (item, onClick) => {
  let chat_logo = "https://mel.school/api/storage/file/1";
  if (item.chat_id == "ADMIN"){
    chat_logo = "https://mel.school/jason.jpg";
  }
  return (
    <div onClick={() => onClick(item.chat_id)} style={{ cursor: "pointer" }}>
      <ChatItem
        avatar={chat_logo} // Adjust as needed
        alt={item.title || "Chat"}
        title={item.title || "Chat"}
        subtitle={item.subtitle}
        date={new Date(item.date)}
        style={{ width: "100%" }}
        unread={item.unread}
      />
    </div>
  );
};

const photoItem = (item, onClick) => {
  let chat_logo = "https://mel.school/api/storage/file/1";
  if (item.chat_id == "ADMIN"){
    chat_logo = "https://mel.school/jason.jpg";
  }
  return (
    <div
      onClick={() => onClick(item.chat_id)}
      style={{ cursor: "pointer", position: "relative" }}
    >
      <img
        src={chat_logo} // Adjust as needed
        alt={item.title || "Chat"}
        className="chat-img"
        style={{ width: "40px", height: "40px", marginRight: "10px" }}
      />
      {item.unread > 0 && <div className="unread-counter">{item.unread}</div>}
    </div>
  );
};

const MyChat = ({ from_id=null }) => {
  const ref = useRef(null);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [activeChat, setActiveChat] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [needScroll, setNeedScroll] = useState(false);

  if (!from_id) {
    from_id = localStorage.getItem("tmp_chat_id");
    if (!from_id) {
      // set random id in local storage
      from_id = "tmp" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem("tmp_chat_id", from_id);
    }
  }

  const currentUserId = from_id;

  const fetchChats = async () => {
    if (TEST) {
      setChats(mockedChats);
      if (mockedChats.length > 0) {
        setActiveChat((prev) => prev || mockedChats[0].chat_id); // Set default if not already set
      }
    } else {
      try {
        const response = await axios.get("/api/messages/chats", {params: {
          from_id: currentUserId,
        }, headers: DataSources.getAuthHeaders()});
        setChats(response.data);
        if (response.data.length > 0) {
          setActiveChat((prev) => prev || response.data[0].chat_id); // Set default if not already set
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    }
  };

  const fetchMessages = async (to_id) => {
    if (TEST) {
      setMessages(mockedMessages[to_id] || []);
    } else {
      try {
        const response = await axios.postForm("/api/messages/get", {
          from_id: currentUserId,
          to_id: to_id,
        }, {headers: DataSources.getAuthHeaders()});
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  const renderChat = async (to_id) => {
    setActiveChat(to_id);
    fetchMessages(to_id);
    setNeedScroll(true);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" || !activeChat) return;

    if (TEST) {
      const newMsg = {
        from_id: currentUserId,
        to_id: activeChat,
        text: newMessage,
        create_dt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, newMsg]);
      setNewMessage("");
    } else {
      try {
        await axios.postForm("/api/messages/add", {
          from_id: currentUserId,
          to_id: activeChat,
          text: newMessage,
        }, {headers: DataSources.getAuthHeaders()});
        setNewMessage("");
        fetchMessages(activeChat);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
    setNeedScroll(true);
  };

  useEffect(() => {
    fetchChats();
    const chatInterval = setInterval(fetchChats, 5000); // Poll every 5 seconds

    return () => clearInterval(chatInterval);
  }, []);

  useEffect(() => {
    if (activeChat) {
      fetchMessages(activeChat);
      const messageInterval = setInterval(
        () => fetchMessages(activeChat),
        1000
      ); // Poll every 1 second

      return () => clearInterval(messageInterval);
    }
  }, [activeChat]);

  useEffect(() => {
    if (ref.current && needScroll) {
      setNeedScroll(false);
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="App">
      <MathJax.MathJaxContext>
      <button className="chat-button" onClick={() => setShowChat(true)}>
        💬
      </button>

      {showChat && (
        <div
          className={`chat-container ${
            window.innerWidth <= 768 ? "chat-container-fullscreen" : ""
          }`}
        >
          <div className="chat-header">
            <span></span>
            <button className="close-button" onClick={() => setShowChat(false)}>&times;</button>
            {window.innerWidth <= 768 && (
              <div className="photo-header">
                {chats.map((chat) => photoItem(chat, renderChat))}
              </div>
            )}
          </div>
          <div className="chat-body">
            {window.innerWidth > 768 && (
              <div className="col-4 overflow-scroll chat-names">
                {chats.map((chat) => chatItem(chat, renderChat))}
              </div>
            )}
            <div className="col overflow-scroll chat-messages" ref={ref}>
              {messages.map((msg, index) => message(msg, activeChat))}
            </div>
          </div>
          <form onSubmit={sendMessage} className="chat-input">
            <input
              type="text"
              className="form-control chat-input-input"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Напишите ваше сообщение..."
              disabled={!activeChat}
            />
            <button
              type="submit"
              className="nb-button btn-primary chat-input-button"
              disabled={!activeChat}
              style={{textAlign: "center"}}
            >
              {"💬"}
            </button>
          </form>
        </div>
      )}
      </MathJax.MathJaxContext>
    </div>
  );
};

export {
  MyChat,
  message,
};
