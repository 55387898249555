import { md5 } from 'js-md5';
import axios from 'axios';

import React, { useState, useEffect } from 'react';


const createUser = (user_id, first_name, last_name) => {
    try {
        const userData = {
            "id": user_id,
            "first_name": first_name,
            "last_name": last_name,
        };
        console.log({headers: getAuthHeaders()});
        const response = axios.post(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/`, userData, {headers: getAuthHeaders()})
        .then(function (response) {
            // handle success
            console.log(response);
          });
        // Handle the response here (e.g., showing a success message)
    } catch (error) {
        console.error("There was an error creating the user:", error.response);
        unSetUser();
        // Handle the error here (e.g., showing an error message)
    }
};

const getAuthHeaders = () => {
    return {
        "vkmid": localStorage.getItem(["UserInfo", "mid"]),
        "vksid": localStorage.getItem(["UserInfo", "sid"]),
        "vksig": localStorage.getItem(["UserInfo", "sig"]),
        "vksecret": localStorage.getItem(["UserInfo", "secret"]),
        "vkexpire": localStorage.getItem(["UserInfo", "expire"]),
    };
}

const setUser = (userInfo) => {
    createUser(userInfo.session.mid, userInfo.session.user.first_name, userInfo.session.user.last_name);
    localStorage.setItem(["UserInfo", "id"], userInfo.session.user.id);
    localStorage.setItem(["UserInfo", "firstName"], userInfo.session.user.first_name);
    localStorage.setItem(["UserInfo", "lastName"], userInfo.session.user.last_name);
    localStorage.setItem(["UserInfo", "expire"], userInfo.session.expire);
    localStorage.setItem(["UserInfo", "mid"], userInfo.session.mid);
    localStorage.setItem(["UserInfo", "secret"], userInfo.session.secret);
    localStorage.setItem(["UserInfo", "sid"], userInfo.session.sid);
    localStorage.setItem(["UserInfo", "sig"], userInfo.session.sig);
}

const unSetUser = () => {
    localStorage.removeItem(["UserInfo", "id"]);
    localStorage.removeItem(["UserInfo", "firstName"]);
    localStorage.removeItem(["UserInfo", "lastName"]);
    localStorage.removeItem(["UserInfo", "expire"]);
    localStorage.removeItem(["UserInfo", "mid"]);
    localStorage.removeItem(["UserInfo", "secret"]);
    localStorage.removeItem(["UserInfo", "sid"]);
    localStorage.removeItem(["UserInfo", "sig"]);
}

const getUser = () => {
    if (process.env.ENVIRONMENT == "local"){
        return getUserMocked();
    }
    if (!localStorage.getItem(["UserInfo", "mid"])){
        return null;
    }
    let userInfo = {
        id: localStorage.getItem(["UserInfo", "mid"]),
        firstName: localStorage.getItem(["UserInfo", "firstName"]),
        lastName: localStorage.getItem(["UserInfo", "lastName"]),
        admin: localStorage.getItem(["UserInfo", "is_admin"]),
    };
    const resp = axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${userInfo.id}/is_admin`, {headers: getAuthHeaders()})
        .then(function (response) {
            // handle success
            console.log(response);
            userInfo.admin = response.data.is_admin;
            localStorage.setItem(["UserInfo", "is_admin"], response.data.is_admin);
          });
    return userInfo;
}

const getUserMocked = () => {
    let userInfo = {
        id: 370549931,
        firstName: "Mel",
        lastName: "Admin",
        admin: true,
    };
    return userInfo;
}

export {getUser, setUser, unSetUser, getAuthHeaders};
