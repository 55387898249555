import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { Link } from 'react-router-dom';
import * as DataSources from './data_sources';
import VkAuth from 'react-vk-auth';
import Editor from "@monaco-editor/react";
import Countdown from 'react-countdown';

import axios from 'axios';

const entryPage = (forceUpdate) => {
    return (<div className="App" style={{animation: "fadeIn 3s"}}>
          <div class="screen-center" style={{animation: "fadeIn 3s"}}>{header(forceUpdate)}</div>
      </div>)
  }
  
const authedHeader = (forceUpdate) => {
    let userInfo = DataSources.getUser();

    const exit = () => {
        DataSources.unSetUser();
        window.location.reload();
    };
    return (
      <header>
      <div class="row mx-md-n5 dialog" style={{width: "100%"}}>
        <div class="col-md">
        <a href='https://vk.com/mel.school' target="_blank">
          <img src={"/landing/logo.png"} className="App-logo" alt="logo" />
        </a>
        </div>
        <div class="col-md title" style={{margin: "4vmin", "font-size": "3vmin"}}>
            Добро пожаловать, {userInfo.firstName} 🐗
        </div>
        <div class="col-md" style={{alignContent: "right", alignItems: "right", textAlign: "right"}}>
          <button type="button" style={{"margin-top": "2vmin", "right": "2vmin"}} class="nb-button default red" onClick={exit}>Выйти</button>
        </div>
      </div>
      </header>
    );
}

const unauthedHeader = (forceUpdate) => {
    const handleVkResponse = (data) => {
        console.warn(data);
        DataSources.setUser(data);
        window.location.reload();  // TODO: use state updating - need to trigger effect from main menu
    };
    return (
      <header>
      <div class="dialog row mx-md-n5">
        <div class="col-md">
        <a href='https://vk.com/mel.school' target="_blank">
          <img src={"/landing/logo.png"} className="App-logo" alt="logo" />
        </a>
        </div>
        <div class="col-md title" style={{margin: "4vmin", "font-size": "3vmin", textAlign: "center"}}>
            МЕЛ.PLATFORM🐗
        </div>
        <div class="col-md" style={{alignContent: "right", alignItems: "right", textAlign: "right"}}>
          <VkAuth apiId={process.env.REACT_APP_VK_APP_ID}  style={{"margin-top": "2vmin"}} class="nb-button green" callback={handleVkResponse}>Войти с помощью VK</VkAuth>
        </div>
      </div>
      </header>
    );
}

const header = (forceUpdate) => {
    let userInfo = DataSources.getUser();
    console.log(userInfo);
    if (userInfo && userInfo != "null"){
        return authedHeader(forceUpdate);
    }
    return unauthedHeader(forceUpdate);
}

const progressBar = (progress) => {
    let color="green";
    if (progress > 30){
        color="green";
    }
    if (progress > 70){
        color="green";
    }

    return (
        <div class="progress">
            <div class={"progress-bar progress-bar-striped progress-bar-animated "+color} role="progressbar" style={{width: `${progress}%`, color: "black"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
        </div>
    )
}

const badge = (text) => {
  return <span class="badge bg-orange badge-inner badge-text">{text}</span>
}

const green_badge = (text) => {
  return <span class="badge bg-green badge-inner badge-text">{text}</span>
}

const rowWithProgress = (title, progress, href, onClick=null, lessonInfo=null, banner=false) => {
    if(!lessonInfo){
        lessonInfo = {};
    }
    const renderTimer = () => {
        if (!lessonInfo.expire_in){
          return (<div></div>)
        }
        if (lessonInfo.expire_in < 0){
          return (<p class="cgreen">{green_badge("Завершено")}</p>)
        }
        return (
          <Countdown date={Date.now() + lessonInfo.expire_in * 1000} />
        )
      }
    let pbar = progressBar(progress);
    if (progress == 1){
        pbar=(<br/>);
    }
    if (progress == 0){
        pbar=(<div class="cgreen">Новые задания!</div>);
    }
    let locked_class = "";
    if (lessonInfo.locked){
          locked_class = " lock-overlay";
          onClick = null;
          href = "#plans";
    }

    let tags = [];

    if (lessonInfo.type == 2){
      tags.push(badge("Тест"));
    }
    //<img src="assets/card-img.svg" loading="lazy" alt="Explore NeoBrutalismCSS" class="card-thumbnail"/>

    return (
      <div class={"col card" + locked_class} style={{maxWidth: "300px", minWidth: "300px"}}>
      <a href={href} onClick={onClick}>
         <div class="card-thumbnail">
          </div>
          <div class="card-content">
                <p>{tags} <strong>{lessonInfo.title}</strong></p>
                    <div>
                    {progressBar(progress)}
                    </div>
                    <p>
                    {renderTimer()}
                    </p>
             </div>
        </a>
   </div>
    )
}


const lessonsList = (data) => {
    const handleClick = (lessonId, title) => {
        localStorage.setItem(["activeLesson"], lessonId);
        localStorage.setItem(["activeLessonTitle"], title);
        localStorage.setItem(["Lesson", lessonId, "activeTask"], -1);
        console.log("activated lesson", lessonId)
      };

    let jsxResult = [];

    let discipline2jsx = {1: [], 2: []};

    for (let i=0 ; i<data.length ; i+=1){
        let item = data[i];
        if (!(item.discipline in discipline2jsx)){
            discipline2jsx[item.discipline] = [];
        }
        var progress = rowWithProgress(item.title, item.progress, "/task", () => handleClick(item.id, item.title), item, true);
        discipline2jsx[item.discipline].push(progress);
    }
    for(let discipline in discipline2jsx){
        jsxResult.push(<div class="row mx-md-n5">{discipline2jsx[discipline]}</div>);
        jsxResult.push(<br/>);
    }
    console.log(jsxResult);
    return jsxResult;
}


const macWindow = (content) => {
    return (
        <div class="dialog" style={{width: "100%"}}>
            <div style={{"margin": "2vmin"}}>{content}</div>
        </div>
    )
}


const backButton = (to="/platform") => {
    return (
        <Link to={to}>
            <button type="button" class="nb-button orange sc" style={{width: "30vmin", marginTop: "2vmin"}}>Назад</button>
        </Link>
    )
}


const htmlRenderer = (htmlString) => {
    return (<div className="Container" dangerouslySetInnerHTML={{__html: htmlString}}></div>)
}



const taskSubmitForm = (taskInfo, forceUpdate, additional_buttons=null) => {
    const onChange = (value) => {
        var valueToSet = null;
        if (value.target){
            valueToSet = value.target.value;
        }else{
            valueToSet = value;
        }
        console.log(value);
        localStorage.setItem(["SubmitContent", taskInfo.id], valueToSet);
    }
    const submitContent = () => {
        const from_local_storage = localStorage.getItem(["SubmitContent", taskInfo.id]);
        if (from_local_storage){
            console.log(from_local_storage);
            return from_local_storage;
        }
        if (taskInfo.type == 1){
            return "Введите ответ";
        }
        if (taskInfo.type == 3){
            return "print('Hey guys type answer here')";
        }
    }

    console.log("task type", taskInfo.type)

    const sendAnswer = () => {
        axios.postForm(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/submit/`, {
            lesson_id: localStorage.getItem(["activeLesson"]),
            task_id: taskInfo.id,
            user_id: DataSources.getUser().id,
            answer: localStorage.getItem(["SubmitContent", taskInfo.id]),
        }, {headers: DataSources.getAuthHeaders()}).then((e) => console.log(e));
        forceUpdate();
    }

    if (!additional_buttons){
        additional_buttons = [];
    }

    if (taskInfo.type == 1){
    return (
      <div>
      <div class="row">
        <div class="col-6"><input id={"task-input" + taskInfo.id} class="nb-input default" style={{width: "100%"}} name="answer" placeholder="Введите ответ" onChange={onChange}></input></div>
        <div class="col-3"><button class="nb-button green" onClick={sendAnswer}>Отправить</button></div>
        <div class="col-3">{additional_buttons}</div>
      </div>
      </div>
    );
    }
    if (taskInfo.type == 3){
        return (
            <div style={{marginTop: "2vmin"}}>
                <Editor
                    style={{"border-radius": "100vmin"}}
                    height="50vh"
                    defaultLanguage="python"
                    theme="vs-dark"
                    value={submitContent()}
                    onChange={onChange}
                />
                <div class="row mx-md-n5">
                    <div class="col-md"><button class="nb-button green rounded grow" onClick={sendAnswer}>Отправить</button></div>
                    {additional_buttons}
                </div>
            </div>
        );
    }
    if (taskInfo.type == 2){
        let archive = null;
        const onChangeFile = (e) => {
            const zip = require('jszip')();
            let files = e.target.files;
            for (let file = 0; file < e.target.files.length; file++) {
              // Zip file with the file name.
              zip.file(files[file].name, files[file]);
            } 
            zip.generateAsync({type: "blob"}).then(content => {
              archive = content;
            });
          }
        const sendArchive = () => {
            axios.postForm(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/submit/`, {
                lesson_id: localStorage.getItem(["activeLesson"]),
                task_id: taskInfo.id,
                user_id: DataSources.getUser().id,
                answer_file: archive,
            }, {headers: DataSources.getAuthHeaders()}).then((e) => console.log(e));
            forceUpdate();
        }
        return <div>
                <input multiple type="file" name="file"
                onChange={onChangeFile}/>
                <div class="row mx-md-n5">
                    <div class="col-md"><button onClick={sendArchive} type="button" class="nb-button green rounded grow">Отправить</button></div>
                    {additional_buttons}
                </div>
        </div>
    }
}


export {
    entryPage,
    header,
    authedHeader,
    unauthedHeader,
    progressBar,
    rowWithProgress,
    lessonsList,
    macWindow,
    backButton,
    htmlRenderer,
    taskSubmitForm,
    badge
}
