import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import * as DataSources from './data_sources';
import axios from 'axios';

const PaymentForm = ({ userId, groups, show, handleClose }) => {

  console.log(groups);
  const [email, setEmail] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/payments/`);
      url.searchParams.append('user_id', userId);
      url.searchParams.append('group_id', selectedGroup);
      url.searchParams.append('email', email);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/payments/`, {params: {
        user_id: userId,
        group_id: selectedGroup,
        email: email
      }, headers: DataSources.getAuthHeaders()});

      window.open(response.data["link"], "_blank");

    } catch (error) {
      console.error('Error creating payment:', error);
    }
  };

  return (
    <div class="dialog" style={{textAlign: "left", fontSize: "3vmin"}}>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label>Эл. почта: </Form.Label>
            <input
              class="nb-input default"
              type="email"
              placeholder="email@example.com"
              style={{fontSize: "2vmin", marginLeft: "2vmin"}}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <br/>
          <Form.Group controlId="formGroups">
            <Form.Label>Выбери учебный план:</Form.Label>
            {groups.map((group) => (
              <div><Form.Check
                type="radio"
                class="nb-checkbox orange"
                key={group.group_id}
                label={`${group.description}`}
                name="group"
                value={group.group_id}
                onChange={(e) => setSelectedGroup(e.target.value)}
                required
              />
              </div>
            ))}
          </Form.Group>
          <br/>
          <button class="nb-button orange" variant="primary" type="submit">
            Купить
          </button>
        </Form>
    </div>
  );
};

export default PaymentForm;
