import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as AppComponents from './App';
import StudentStatistics from './AdminApp';

import * as landing_comps from './Landing';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";

const router = createBrowserRouter([
  {
    path: '/',
    element: <landing_comps.Landing />
  },
  {
    path: '/lesson',
    element: <AppComponents.Lesson />
  },
  {
    path: '/task',
    element: <AppComponents.Task />
  },
  {
    path: '/stats',
    element: <StudentStatistics />
  },
  {
    path: '/platform',
    element: <AppComponents.MainMenu />
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
