import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JSZip from 'jszip'; // Import JSZip module
import './App.css';

import * as DataSources from './data_sources';

function StudentStatistics() {
    const [statistics, setStatistics] = useState([]);
    const [activeGroupId, setActiveGroupId] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/student-statistics`, {headers: DataSources.getAuthHeaders()})
            .then(response => response.json())
            .then(data => {
                setStatistics(data);
                if (data.length > 0) {
                    // Set the first group as the active tab initially
                    setActiveGroupId(data[0].group_id);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const getSubmitResult = (submits, taskId, taskMaxResult) => {
        const submit = submits.find(s => s.task_id === taskId);
        let saved = "";
        if (!submit.checked){
            let archive = null;
            const onChangeFile = (e) => {
                const zip = new JSZip(); // Create a new instance of JSZip
                let files = e.target.files;
                for (let file = 0; file < e.target.files.length; file++) {
                // Zip file with the file name.
                zip.file(files[file].name, files[file]);
                } 
                zip.generateAsync({type: "blob"}).then(content => {
                archive = content;
                });
            }
            const sendArchive = () => {
                axios.postForm(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/manual_check/`, {
                    submit_id: submit.id,
                    result: localStorage.getItem(["stats", "submit", submit.id, "result"]),
                    check_comment: localStorage.getItem(["stats", "submit", submit.id, "check_comment"]),
                    check_file: archive,
                },  {headers: DataSources.getAuthHeaders()}
                ).then((e) => console.log(e)).then(saved="Сохранено");
            }
                return (
                    <div style={{backgroundColor: "orange", borderRadius: "1vmin"}}><div style={{margin: "1vmin"}}>
                    <a href={`${process.env.REACT_APP_BACKEND_ADDRESS}/api/download/submit/${submit.id}`}>Скачать</a><br/>
                    <input style={{width: "15vmin"}} type="number" placeholder={`Оценка из ${taskMaxResult}`} id="result" onChange={
                        (e) => localStorage.setItem(["stats", "submit", submit.id, "result"], e.target.value)
                    }></input>
                    <input style={{width: "70%"}} placeholder="Коммент" id="check_comment" onChange={
                        (e) => localStorage.setItem(["stats", "submit", submit.id, "check_comment"], e.target.value)
                    }></input><br/>
                    <input multiple type="file" name="Файлы" onChange={onChangeFile}/>
                    <button onClick={sendArchive} type="button" class="nb-button">Отправить</button>{saved}
                    </div></div>
                )
        }
        return `${submit ? submit.result : 0}/${taskMaxResult}`;  // Return 0 if no submit found
    };

    const renderGroupContent = (group) => (
        group.lessons.map(lesson => (
            <div key={lesson.lesson_id} className="col">
                <h3 class="title" style={{fontSize:"2vmin"}}>{lesson.title} ({lesson.lesson_id})</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>User</th>
                            {lesson.tasks && lesson.tasks.map(task => (
                                <th key={task.id}>{task.title} ({task.id})</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lesson.users && lesson.users.map(user => (
                            <tr key={user.user_id}>
                                <td>{user.user_id  + ' ' + user.first_name + ' ' + user.last_name}</td>
                                {lesson.tasks && lesson.tasks.map(task => (
                                    <td key={task.id}>{getSubmitResult(user.submits, task.id, task.weight)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ))
    );
    

    return (
        <div className="container">
            <div className="tab-header">
                {statistics.map(group => (
                    <button
                        key={group.group_id}
                        className={`tab-item title grey ${activeGroupId === group.group_id ? 'active' : ''}`}
                        onClick={() => setActiveGroupId(group.group_id)}
                        style={{fontSize:"2vmin"}}
                    >
                        Group: {group.group_id}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {statistics.filter(group => group.group_id === activeGroupId).map(renderGroupContent)}
            </div>
        </div>
    );
}

export default StudentStatistics;
