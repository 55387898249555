import 'bootstrap/dist/css/bootstrap.css';
import "video-react/dist/video-react.css";

import { MessageBox, ChatItem } from "react-chat-elements";

import React, { useState, useEffect } from 'react';

import './App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as DataSources from './data_sources';
import * as Elements from './elements';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Modal from 'react-bootstrap/Modal';

import * as chat_components from "./MyChat";
import * as MathJax from "better-react-mathjax";

import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    elements,
  } from 'chart.js';

let window_height = 0;
let window_width = 0;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
function LineChart({ chartData }) {
  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>Line Chart</h2>
      <Line
        data={chartData}
      />
    </div>
  );
}


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };


  const TextWithLineBreaks = (text) => {
    // Handle null or undefined text
    if (text == null) {
      return null;
    }
  
    // Split the text by newlines
    const lines = text.split('\n');
  
    return (
      <div>
        {lines.map((line, index) => {
          // Check for [green] or [violet] tags and remove them from the line
          const greenTag = '[green]';
          const violetTag = '[violet]';
          const sourceCode = '[block]';
  
          let className = '';
          if (line.includes(greenTag)) {
            className = 'badge bg-orange text-white badge-inner badge-text';
            line = line.replace(greenTag, '');
          } else if (line.includes(violetTag)) {
            className = 'violet';
            line = line.replace(violetTag, '');
          } else if (line.includes(sourceCode)) {
            className = 'sc';
            line = line.replace(sourceCode, '');
          }
  
          return (
            <React.Fragment key={index}>
              <span className={className}>{line}</span>
              {index < lines.length - 1 && <br />}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

const TextWithImage = (text, image, right_text=false, videocircle=false, custom=false, header=false) => {
    let text_align = "left";
    let elem_width = "50%";
    if (window_width < 700 && window_width > 0){
        right_text = false;
        text_align = "left";
        elem_width = "100%";
    }

    let style = {marginTop: "30%", marginBottom: "30%", marginLeft: "10%", marginRight: "10%", width: "80%", fontSize: "3vmin", textAlign: text_align};
    if (header){
        style = {marginTop: "40%", marginBottom: "40%", marginLeft: "10%", marginRight: "10%", width: "80%", fontSize: "3vmin", textAlign: text_align};
    }
    let div_class = "dialog title";
    if (!header){
      div_class = "dialog";
    }
    let TEXT = <div class="col-5" style={{width: elem_width}}>
        <div class={div_class} style={style}>{TextWithLineBreaks(text)}</div>
        </div>
      
    let image_style = {};


    if (typeof image === 'string' && image){
      console.log(image);
      if (image.includes(".svg")){
        image_style = {position: "center", width: "100%", height: "100%"};
      }else{
        image_style = {position: "center", width: "100%"};
      }
    }
        
    let IMAGE = <div class="col-5" style={{width: elem_width}}>
        <img src={image} style={image_style}/>
        </div>
    if (videocircle){
        IMAGE = <div class="col-5" style={{width: elem_width, alignContent: "center", textAlign: "center"}}>
            <video id="video" class="round" src={image} style={{width: "50vmin", height: "50vmin"}} controls></video>
        </div>
    }

    if (custom){
        IMAGE = <div class="col-5" style={{width: elem_width, alignContent: "center", textAlign: "center"}}>
            {image}
        </div>
    }
    
    if (right_text){
        let TMP = TEXT;
        TEXT = IMAGE;
        IMAGE = TMP;
    }
    
    return <div class="row" style={{margin: "5vmin"}}>
    {TEXT}
    {IMAGE}
</div>
}

const VioletLine = (text, rotated=true) => {
    let classs = "block violet growmax";
    if (rotated) {
        classs += " rotatedplus";

    }
    return <div class={classs} width="100%" style={{marginTop: "0px", marginBottom: "0px"}}>
        <p width="100%">{text.repeat(100)}</p>
    </div>
}

const GreenLine = (text, rotated=true) => {
    let classs = "block growmax";
    if (rotated) {
        classs += " rotatedminus";
    }
    return <div class={classs} width="100%" style={{marginTop: "0px", marginBottom: "0px", backgroundColor:"#40d39c"}}>
        <p width="100%">{text.repeat(100)}</p>
    </div>
}

const CourseBox = (title, text, try_link=null, buy_link=null, color="grey") => {

  return    <div class="card col-5" style={{minWidth: "300px !important"}}>
  <a href={try_link} target="_blank">
     <div class="card-thumbnail">
        <img src="landing/course_logo.svg" loading="lazy" class="card-thumbnail" style={{width:"50vmin"}}/>
      </div>
      <div class="card-content">
         <div class="text-small"></div>
                <p>
                    <strong>{title}</strong>
                </p>
                <div>{text}</div>
                <br/>
                <button class="nb-button orange">Попробовать</button>
         </div>
    </a>
  </div>
}

const Footer = () => {
  let call_icons_width = "30px";
  return <div style={{"margin": "2vmin"}}><div id="contacts"/>
      {Elements.badge("Наши контакты")}

        <div>
            <div class="title h2 dialog" style={{position:"absolute", left:"30%", width: "30%"}}>
            MEL.SCHOOL 2024<br/>+7(777)7777777
                <div class="row">
                    <div class="col">
                        <a href="https://vk.com/mel_school" target="_blank">
                            <img src="/landing/vk.png" width={call_icons_width}/>
                        </a>
                    </div>
                    <div class="col">
                        <a href="https://t.me/mel_school" target="_blank">
                            <img src="/landing/telegram.png" width={call_icons_width}/>
                        </a>
                    </div>
                    <div class="col">
                        <a href="https://t.me/mel_school" target="_blank">
                            <img src="/landing/whatsapp.png" width={call_icons_width}/>
                        </a>
                    </div>
                    <div class="col">
                        <a href="https://t.me/mel_school" target="_blank">
                            <img src="/landing/gmail.png" width={call_icons_width}/>
                        </a>
                    </div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                </div>
           </div>
        <img src="/landing/with_phone.png" width="100%" />
        </div></div>
};

const Landing = () => {
    const [, updateState] = useState();
    const [lessonsData, setLessonsData] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const { height, width } = useWindowDimensions();
    window_width = width;
    window_height = height;
    //if (width <= 800 && height > width){
    //    return <body style={{backgroundColor: "white", height: height+"px", textAlign: "center"}}><img src="/landing/rotate.jpg" style={{width: "100%"}}/><h1>Поверните устройство горизонтально.</h1></body>
    //}

    const data = {
        labels: Array.from(Array(40).keys()),
        datasets: [
          {
            label: "sin(x/2)",
            data: Array.from(Array(40).keys()).map((i) => Math.sin(i/2)),
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
            cubicInterpolationMode: 'monotone',
          },
          {
            label: "cos(x/2)",
            data: Array.from(Array(40).keys()).map((i) => Math.cos(i/2)),
            fill: false,
            borderColor: "#742774",
            cubicInterpolationMode: 'monotone',
          }
        ],
      };

    let options = {};

    let call_icons_width = "100px"

    const message_stream = async (params) => {
		try {
			const input = params.userInput;

			let text = input;
			await params.streamMessage(text);
		} catch (error) {
			await params.injectMessage("Unable!");
		}
	};

  const message_flow = {
		start: {
			message: "Приветствуем в МЕЛ! Как успехи в подготовке?",
			path: "loop",
		},
		loop: {
			message: async (params) => {
				await message_stream(params);
			},
			path: "loop",
		}
	};

    return (
    <div className="App" style={{minWidth: "375px"}}>
        <MathJax.MathJaxContext>
        <body className="App-body geared" data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50" style={{margin: "0px"}}>
        
        <div class="row" style={{margin: "0px", position: "absolute", width: "100%", fontSize: "2vmin"}}>
        <div class="col" style={{margin: "0px"}}>
            <img src="/landing/logo.png" width="70vmin" />
        </div>
        <div class="col" style={{margin: "0px", fontSize: "2vmin"}}><a href="#cources"><button class="nb-button green" style={{width: "90%"}}>пробный доступ</button></a></div>
        <div class="col" style={{margin: "0px", fontSize: "2vmin"}}><a href="#about"><button class="nb-button default" style={{width: "90%"}}>преимущества</button></a></div>
        <div class="col" style={{margin: "0px", fontSize: "2vmin"}}><a href="/platform"><button class="nb-button default" style={{width: "90%"}}>платформа</button></a></div>

        <div class="col" style={{margin: "0px", fontSize: "2vmin"}}><a href="#contacts"><button class="nb-button default" style={{width: "90%"}}>контакты</button></a></div>
        </div>
        <div style={{height: "90vmin", margin: "0px", overflow: "hidden"}}>
        <br/>
        {TextWithImage("МЕЛ - ПЛАТФОРМА ИНТЕРАКТИВНОЙ ПОДГОТОВКИ К ЕГЭ", "/landing/boar_with_books.svg", false, false, false, true)}
        </div>
        <a href="#cources">{GreenLine("ПОПРОБУЙ НАШИ КУРСЫ БЕСПЛАТНО | ", false)}</a>
        <br/><br/><br/><br/><br/>
        <div id="about"/>
        {TextWithImage("[green]Хочешь поступить на IT специальность?\n\nПосмотри обращение от сооснователя нашей платформы.", "/landing/video10.mp4", true, true)}
        {TextWithImage(`[green]«МЕЛ» поможет со всеми проблемами:

- Быстро научишься программировать.

- Начнешь математически мыслить.

- Узнаешь будущие трудности в ВУЗе и  даже первого трудоустройства  аналитика и программиста.`, "/landing/with_students.png")}
        <a href="#cources">{GreenLine("ПЕРЕХОДИ К ПРОБНЫМ УРОКАМ | ")}</a>
        {TextWithImage(`Внутри тебя ждет 200+ часов структурированных видео от молодых айтишников - опытных репетиторов.\n\nC крутыми заданиями и шпаргалками к каждому уроку.`, "/landing/video_logo.png", true)}
        {VioletLine("ПОЧЕМУ НАШИ МАТЕРИАЛЫ - САМЫЕ КРУТЫЕ | ")}
        {TextWithImage("Визуализации математических функций, анимации алгоритмов.", <div style={{backgroundColor: "white", borderRadius: "10px"}}><Line data={data} options={options} /></div>, false, false, true)}
        {TextWithImage("Интерактивные примеры кода - измени и запусти!", <code-runner language="python3">
{`# Длина и ширина прямоугольника
length = 10
width = 5

# Напишите формулу для вычисления периметра
area = (length + width) * 2
print(area)
`}
</code-runner>, true, false, true)}
        {TextWithImage(
            "[green]Искусственный Интеллект.\n\nПоможет с любым теоретическим вопросом по математике и информатике. Тыкни на значок чата в правом нижнем углу и опробуй!",
            <p style={{color: "black", textAlign: "left"}}>
              {chat_components.message(
                {text: "Напомни теорему косинусов и напиши код на python для подсчета углов треугольника по сторонам, пожалуйста.",
                from_id: 1,
                create_dt: Date.now(),
                },
                0
              )}
              {chat_components.message(
                {text: `Теорема косинусов гласит, что в любом треугольнике сторона, противолежащая углу C, равна квадратному корню из суммы квадратов двух других сторон, умноженному на косинус угла C. Математически это выглядит так:

\\[c^2 = a^2 + b^2 - 2ab \\cdot \\cos(C)\\]

А вот пример кода на Python для подсчета углов треугольника по заданным сторонам:

\`\`\`python
import math

def calculate_angles(a, b, c):
    A = math.degrees(math.acos((b**2 + c**2 - a**2) / (2 * b * c)))
    B = math.degrees(math.acos((a**2 + c**2 - b**2) / (2 * a * c)))
    C = 180 - A - B
    return A, B, C

a = 3
b = 4
c = 5

angles = calculate_angles(a, b, c)
print("Углы треугольника:", angles)
\`\`\`

Здесь функция \`calculate_angles\` принимает длины сторон треугольника и возвращает углы в градусах. В примере заданы стороны треугольника \(a = 3\), \(b = 4\), \(c = 5\), и выводятся вычисленные углы.`,
                from_id: 1,
                create_dt: Date.now(),
                },
                1
              )}
            </p>,
            false, false, true)}
        {GreenLine("КЕШБЭК ПРИ ВЫПОЛНЕНИИ ВСЕХ ЗАДАНИЙ | ")}
        {VioletLine("ВЫБЕРИ ФОРМАТ КУРСА НА СВОЙ ВКУС | ")}
        <div id="cources"/>
        {TextWithImage("ВЫБЕРИ СВОЙ УЧЕБНЫЙ ПЛАН", "/landing/boar_with_lamp.svg")}
        <div class="row">
        {CourseBox("КАБАНАТОР-7000", "Полный доступ ко всем курсам с проверкой домашних заданий, живыми вебинарами и личным ментором. Кликай и переходи на платформу.", "/platform", "/platform", "gold")}
            {CourseBox("ПРОФЕССОР КАБАНОВИЧ", "Доступ к курсу по математике с проверкой домашних заданий, личным ментором, ИИ-помощником и онлайн-консультациями. Кликай и переходи на платформу.", "/platform", "/platform")}
            {CourseBox("SENIOR КАБАНАТОР", "Доступ к курсу по информатике с проверкой домашних заданий, личным ментором, ИИ-помощником и онлайн-консультациями. Кликай и переходи на платформу.", "/platform", "/platform")}
            {CourseBox("SENIOR КАБАНАТОР", "Доступ к курсу по информатике с проверкой домашних заданий, личным ментором, ИИ-помощником и онлайн-консультациями. Кликай и переходи на платформу.", "/platform", "/platform")}
        </div>
        <div id="" style={{"marginTop": "12vmin"}}/>
        {GreenLine("ЖДЕМ ТЕБЯ | ")}
        <div id="reviews" style={{"marginTop": "12vmin"}}/>
        <div class="row"><div class="col-5">{Elements.badge("Наши отзывы.")}</div><div class="col-7"><Carousel responsive={responsive} swipeable={true} autoPlay={true} infinite={true}>
        <div class="dialog sky-blue" align="center"><div class="avatar bg-pale-red"><img src="https://mel.school/api/storage/file/7"/></div> <div class="title h2 sc">"Занимаясь с Александром Микаэловичем, полюбил математику." - Лева Шамардин</div></div>
        <div class="dialog sky-blue" align="center"><div class="avatar bg-pale-red"><img src="/landing/course_logo.png"/></div><div class="title h2 sc">"Порекомендовал племяннику школу МЕЛ. Очень хорошо подтянули его по математике и информатике, парень даже интересоваться программированием начал." - Григорий Белятский</div></div>
        <div class="dialog sky-blue" align="center"><div class="avatar bg-pale-red"><img src="https://mel.school/api/storage/file/6"/></div><div class="title h2 sc">"Спасибо большое, что помогли с подготовкой!" - Нина Скорб</div></div>
        <div class="dialog sky-blue" align="center"><div class="avatar bg-pale-red"><img src="https://mel.school/api/storage/file/5"/></div><div class="title h2 sc">"Вместе с ребятами сдал информатику на 91 балл!!" - Максим Маркелов</div></div>
        </Carousel></div></div>

        {Footer()}
        </body>
        <chat_components.MyChat />
        </MathJax.MathJaxContext>
    </div>
    );
}

export {
  Landing,
  TextWithImage,
  VioletLine,
  GreenLine,
  Footer
}

// https://react-chatbotify.tjtanjin.com/docs/introduction/quickstart/