// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&family=Unbounded:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Source Code Pro", sans-serif;
  font-size: small;
  background-color: black;
}

.title {
  font-family: "Unbounded", sans-serif;
  font-size: 70px;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,0CAA0C;EAC1C,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,YAAY;AACd;;AAEA;EACE;aACW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&family=Unbounded:wght@500&display=swap');\n\nbody {\n  margin: 0;\n  font-family: \"Source Code Pro\", sans-serif;\n  font-size: small;\n  background-color: black;\n}\n\n.title {\n  font-family: \"Unbounded\", sans-serif;\n  font-size: 70px;\n  color: white;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
