import 'bootstrap/dist/css/bootstrap.css';

import React, { useState, useEffect } from 'react';

import './App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as DataSources from './data_sources';
import * as Elements from './elements';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Modal from 'react-bootstrap/Modal';

import * as chat_components from './MyChat';

import * as landing_comps from './Landing';

import PaymentForm from './Payment';




const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive_single = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 0 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  }
};


const lessonRenderer = (lessonContent) => {

  const getAttachmentsButtons = () => {
    let buttons = [];
    if (lessonContent.attachments){
      for(let key in lessonContent.attachments){
        buttons.push(
          <div class="row mx-md-n5">
            <Link to={lessonContent.attachments[key]}><button type="button" class="nb-button">{key}</button></Link>
          </div>
        )
      }
    }

    return buttons;
  }

  const videoPart = () => {
    if (lessonContent.video_link){
      return (<iframe src={lessonContent.video_link}
      frameborder='0'
      allow='autoplay; encrypted-media'
      allowfullscreen
      title='video'
      class="embed-responsive-item"
      />)
    }
    return null
  }

  return (
    <div>
    <p class="title">
          {lessonContent.title}
    </p>
    {videoPart()}
    {Elements.macWindow(Elements.htmlRenderer(lessonContent.htmlText))}
        {getAttachmentsButtons()}
    </div>
  )
}

const submitsRenderer = (submitInfo, tasksInfo, activeTask) => {
  console.log(submitInfo);
  let result = [
    (<div style={{marginTop: "10vmin"}}>{Elements.badge("История посылок")}</div>),
    (
      <div class="row mx-md-n5">
        <div class="col">Ответ</div>
        <div class="col">Результат</div>
        <div class="col">Комментарий</div>
      </div>
    )
  ];
  let taskWeight = tasksInfo[activeTask || 0].weight;
  for(let submit of submitInfo){
    let additionalClass = "";
    if (taskWeight == submit.result){
      additionalClass = " green"
    }
    let answer = submit.answer;
    if(tasksInfo[activeTask || 0].type == 2){
      answer = (<a style={{fontSize: "3vmin"}} href={`${process.env.REACT_APP_BACKEND_ADDRESS}/api/download/submit/${submit.id}`}>Скачать</a>);
    }
    if(tasksInfo[activeTask || 0].type == 3){
      answer = (<a style={{fontSize: "3vmin"}} href={`${process.env.REACT_APP_BACKEND_ADDRESS}/api/onepagers/submits/${submit.id}`}>Посмотреть</a>);
    }
    let submit_result = `${submit.result | 0}/${taskWeight}`;
    if(!submit.checked){
      submit_result = "На проверке";
    }
    result.push(
      <div class={"row mx-md-n5" + additionalClass} style={{width: "100%", border: "2px solid black", borderRadius: "1vmin", padding: "1vmin"}}>
        <div class="col">{answer}</div>
        <div class="col">{submit_result}</div>
        <div class="col">{Elements.htmlRenderer(submit.check_comment)}</div>
      </div>
    )
  }
  return result;
}

function PopUp(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button class="nb-button default" variant="primary" onClick={handleShow}>
        {props["title"]}
      </button>

      <Modal show={show} onHide={handleClose} class="body">
        <Modal.Header closeButton>
          <Modal.Title>{props["title"]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Elements.htmlRenderer(props["content"])}</Modal.Body>
      </Modal>
    </>
  );
}


const tasksRenderer = (lessonId=0, tasksInfo=[], lessonInfo, submitInfo, forceUpdate=null) => {
  window.scrollTo(0, 0);
  const getTasksButtons = (lessonId, activeTask) => {
    let jsxButtons = [];
    let indexTracker = 0;

    const handleClick = (activeTaskIndex) => {
      localStorage.setItem(["Lesson", lessonId, "activeTask"], activeTaskIndex);
      forceUpdate();
    };

    let lesson_btn_class = "nb-button default";

    if (activeTask == -1){
      lesson_btn_class += " orange";
    }

    jsxButtons.push(
      <button type="button" class={lesson_btn_class} style={{width: "calc(100px)", marginLeft: "1vmin", marginTop: "2vmin"}} onClick={() => handleClick(-1)}>Урок</button>
    )

    for(let taskInfo of tasksInfo){
      let buttonClass = "nb-button default ";

      for (let submit of taskInfo.submits){
        if (submit.result > 0){
          buttonClass += " green ";
        }
      }
  
      if (indexTracker == activeTask){
        buttonClass += "orange ";
      }

      const currentIndex = indexTracker;  // object copying
      jsxButtons.push(
          <button type="button" class={buttonClass} style={{width: "calc(16px + 5vmin)", marginLeft: "1vmin", marginTop: "2vmin"}}  onClick={() => handleClick(currentIndex)}>{currentIndex}</button>
      )
      indexTracker += 1;
    }
    return jsxButtons;
  }

  const renderTask = (lessonId, activeTask, tasksInfo, forceUpdate, lessonContent, next_button) => {

    if (tasksInfo.length == 0){
      return (<div></div>)
    }

    console.log("tasks info" , tasksInfo)

    if (tasksInfo.length <= activeTask){
      activeTask = 0;
      localStorage.setItem(["Lesson", lessonId, "activeTask"], activeTask);
    }

    let edit_task = [];
    if (DataSources.getUser().admin && activeTask >= 0){
      edit_task.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/task/edit/" + tasksInfo[activeTask].id} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    }

    let additional_buttons = [];

    if (tasksInfo.length > activeTask){
      if (tasksInfo[activeTask].htmlHelp){
        additional_buttons.push(
          <div class="col-sm">
              <PopUp title={"Подсказка"} content={tasksInfo[activeTask].htmlHelp}/>
          </div>
        )
      }
    }

    let submitFormObj = Elements.taskSubmitForm(tasksInfo[activeTask], forceUpdate, additional_buttons);
    if (lessonInfo.type == 2){
      if (lessonInfo.expire_in < 0){
        submitFormObj = <p class="cgreen">Тест завершен.<br/></p>;
      }
      if (submitInfo.length > 2){
        submitFormObj = <p class="cgreen">Больше нет попыток.<br/></p>;
      }
    }
    // TODO: Add backend submits ttl/count checker for tests.

    return (
    <div class="row mx-md-n5 dialog">
      <div class="col-md">
      {Elements.macWindow(
        <div> {Elements.htmlRenderer(tasksInfo[activeTask].htmlText)} <br/> <img alt="" class="img-rounded" src={tasksInfo[activeTask].img_link}/> </div>
      )
      }
      <br/>
      {next_button}
      </div>
      <div class="col-md">
      <div>{Elements.badge("Отправить ответ")}</div>
      {submitFormObj}
      {submitsRenderer(submitInfo, tasksInfo, activeTask)}
      </div>
    </div>
    )

  }

  let edit_task = [];
  if (DataSources.getUser().admin){
    edit_task.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/task/create"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    edit_task.push(<div class="row mx-md-n5">Current lessonId={lessonId}</div>)
    edit_task.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/lesson2-task/create"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
  }

  let activeTask = localStorage.getItem(["Lesson", lessonId, "activeTask"]) || -1;

  console.log(activeTask);
  console.log("lessonInfo.progress", lessonInfo.progress)

  let tasks_length = tasksInfo.length;
  let next_button = null;
  if (activeTask >= -1 && activeTask < tasks_length - 1){
    
    const handleClick = () => {
      activeTask = Number(activeTask) + 1;
      localStorage.setItem(["Lesson", lessonId, "activeTask"], activeTask);
      forceUpdate();
    };
    next_button = <button type="button" class={"nb-button green"} style={{width: "200px", marginLeft: "1vmin"}}  onClick={() => handleClick()}>Далее</button>
  }

  if (activeTask == -1){

    return <div>
      <div class="row mx-md-n5">
        {getTasksButtons(lessonId, activeTask)}
        <div class="col">
              {Elements.backButton()}
        </div>
      </div>
          {lessonRenderer(lessonInfo)}
          <br/>
          {next_button}
    </div>
  }


  return (
    <div>
      <div class="row mx-md-n5">
        {getTasksButtons(lessonId, activeTask)}
        <div class="col-2">
              {Elements.backButton()}
        </div>
      </div>
          {renderTask(lessonId, activeTask, tasksInfo, forceUpdate, null, next_button)}
    </div>
  )
}

const MainMenu = () => {
  const [, updateState] = useState();
  const [lessonsData, setLessonsData] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [activeGroup, setActiveGroup] = useState(0);
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!DataSources.getUser()){
      return;
    }
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${DataSources.getUser().id}/groups`, {headers: DataSources.getAuthHeaders()});
        const pricedGroups = response.data.filter(group => group.month_price > 0);
        setUserGroups(pricedGroups);
        if (activeGroup == 0 && pricedGroups.length > 0){
          setActiveGroup(pricedGroups[0].group_id);
        }
        console.log(pricedGroups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    fetchGroups();
  }, []);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (!DataSources.getUser()){
      return;
    }
    let user_id = DataSources.getUser().id;
    axios
       .get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${user_id}/lessons/`, {headers: DataSources.getAuthHeaders(), params: {group_id: activeGroup}})
       .then((response) => {
          setLessonsData(response.data);
       })
       .catch((err) => {
          console.log(err);
       });
  }, [activeGroup]);

  if (!DataSources.getUser()){
    return Elements.entryPage(forceUpdate);
  }

  let new_lesson = [];
  if (DataSources.getUser().admin){
    new_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/lesson/create"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    new_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/group/list"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    new_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/group2-lesson/list"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    new_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/api/form/submit_check/list"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
  }

  let chat = <chat_components.MyChat from_id={DataSources.getUser().id} />;
  let admin_part = null;
  if (!DataSources.getUser().admin){
    chat = <chat_components.MyChat from_id={"ADMIN"} />
    admin_part=<Carousel responsive={responsive_single} swipeable={true} infinite={true}>
        {new_lesson}
      </Carousel>;
  }

  let group_buttons = [
    Elements.badge("Мои курсы"),
  ];
  let group_video = null;
  let group_intro = null;
  for (let i = 0; i < userGroups.length; i++){
    let btn_cls = "nb-button default ";
    const group = userGroups[i];
    if (group.group_id == activeGroup){
      btn_cls += "orange";
      group_intro = group.intro_text;
      group_video = group.intro_video;
    }
    group_buttons.push(<button type="button" class={btn_cls} style={{width: "250px", marginLeft: "5vmin", fontSize: "2.5vmin"}}  onClick={() => setActiveGroup(group.group_id)}>{group.description}</button>)
  }

  return (
    <div className="App">
      {Elements.header(forceUpdate)}
      <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50" class={"geared"} style={{minHeight: "2000px", backgroundColor: "rgba(255, 255, 255, 0)"}}>
        <div class="App-body">
        {landing_comps.TextWithImage("[green]Добро пожаловать на платформу!\n\nКирилл расскажет, как ей пользоваться!", "/landing/video10.mp4", false, true)}
        <br/>
        {group_buttons}
        <br/><br/>
        <div class="dialog soft-violet">
        <div class="row">
        <div class="col-md">
          <video src={group_video} style={{width: "80vmin", height: "50vmin", borderRadius: "3vmin"}} controls></video>
        </div>
        <div class="col-md">{group_intro}</div>
        </div>
          {Elements.lessonsList(lessonsData, activeGroup)}
          {admin_part}
        </div>
          <p class="title h2" id="plans">
            {Elements.badge("Платные услуги")}
        </p>
      <div class="row">
      <div class="col-md"><video id="video" class="round" src={"/landing/video10.mp4"} style={{width: "50vmin", height: "50vmin"}} controls></video></div>
        <div class="col-md">
          <PaymentForm userId={DataSources.getUser().id} groups={userGroups} show={showModal} handleClose={handleClose} />
        </div>
      </div>
        </div>
      <br/><br/><br/><br/><br/><br/>
      {landing_comps.Footer()}
        
      </body>
      {chat}
    </div>
  );
}

const Lesson = () => {
  const [, updateState] = useState();
  const [lessonData, setLessonData] = useState([]);
  const forceUpdate = React.useCallback(() => {updateState({});}, []);

  useEffect(() => {
    if (!DataSources.getUser()){
      return;
    }
    let user_id = DataSources.getUser().id;
    let activeLesson = localStorage.getItem(["activeLesson"]);
    axios
       .get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${user_id}/lessons/${activeLesson}/content/`, {headers: DataSources.getAuthHeaders()})
       .then((response) => {
          console.log("data for lesson:", response.data)
          setLessonData(response.data);
       })
       .catch((err) => {
          console.log(err);
       });
  }, []);

  if (!DataSources.getUser()){
    return Elements.entryPage(forceUpdate);
  }
  let edit_lesson = [];
  if (DataSources.getUser().admin){
    edit_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/lesson/edit/"+lessonData.id} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
    edit_lesson.push(<iframe src={process.env.REACT_APP_BACKEND_ADDRESS+"/admin/group2-lesson/list"} samesite={null} and secure={true} width="90%" class="rounded"></iframe>);
  }
  return (
    <div className="App">
      {Elements.header(forceUpdate)}
      <body className="App-body">
        {lessonRenderer(lessonData)}
        <Carousel responsive={responsive_single} swipeable={true} infinite={true}>
        {edit_lesson}
        </Carousel>
      </body>
    </div>
  );
}

const Task = () => {
  const [forceUpdateStateValue, updateState] = useState();
  const [tasksData, setTasksData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [submitData, setSubmitData] = useState([]);
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (!DataSources.getUser()){
      console.log("Cant get user")
      return;
    }
    let user_id = DataSources.getUser().id;
    let activeLesson = localStorage.getItem(["activeLesson"]);
    axios
       .get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${user_id}/lessons/${activeLesson}/tasks/`,  {headers: DataSources.getAuthHeaders()})
       .then((response) => {
          console.log("Tasks data for lesson:", response.data)
          setTasksData(response.data);
          // getting submits data after got tasksData
          axios
              .get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${user_id}/lessons/${activeLesson}/tasks/${response.data[localStorage.getItem(["Lesson", activeLesson, "activeTask"]) || 0].id}/submits`, {headers: DataSources.getAuthHeaders()})
              .then((submits_response) => {
                  console.log("Submits data for task:", submits_response.data)
                  setSubmitData(submits_response.data);
                  console.log("setting up submit data", submitData);
              })
              .catch((err) => {
                  console.log(err);
              });
       })
       .catch((err) => {
          console.log(err);
       });
    
    axios
       .get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/users/${user_id}/lessons/${activeLesson}/content/`,  {headers: DataSources.getAuthHeaders()})
       .then((response) => {
          console.log("Lesson data for lesson:", response.data)
          setLessonData(response.data);
       })
       .catch((err) => {
          console.log(err);
       });
  }, [forceUpdateStateValue]);


  if (!DataSources.getUser()){
    return Elements.entryPage(forceUpdate);
  }
  return (
    <div className="App-body geared">
        {tasksRenderer(lessonData.id, tasksData, lessonData, submitData, forceUpdate)}
        <chat_components.MyChat from_id={DataSources.getUser().id} />
    </div>
  );
}

export {MainMenu, Task, Lesson};
